<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>
<PreloaderComponent :enable=enable></PreloaderComponent>
<v-container fluid v-if='enable'>
<v-card
  elevation="2" style="margin-top:20px"
>
<div class="row">
  <div class='col-md-12' style="padding-top:0px">
        <table class="table table-striped table-hover" id="content" style="border-top:none">
          <thead>
            <tr>
              <th style="border-right:1px solid #c0c0c0;text-align:left">MAGASIN</th>

              <th v-if="filter_rate_id == 1" style="border-right:1px solid #c0c0c0;text-align:right">VENTES (HTG)</th>
              <th v-if="filter_rate_id == 2" style="text-align:right">VENTES (USD)</th>
            </tr>
            <tr style="color:black;background:#dee2e6">
              <th style='text-align:left;border-right:1px solid #c0c0c0'>TOTAL</th>
              <th v-if="filter_rate_id == 1" style="border-right:1px solid #c0c0c0;text-align:right">{{ total(1) | formatNumber }}</th>
              <th v-if="filter_rate_id == 2" style="border-right:1px solid #c0c0c0;text-align:right">{{ total(2) | formatNumber }}</th>
            </tr>
          </thead>
          <tbody style="margin-top:40px">
            <tr v-for="store in rows" :key="store.store_id">
              <td style="border-right:1px solid #c0c0c0" class="text-left">{{ store.store }}</td>
              <td v-if="filter_rate_id == 1 && store.HTG && !store.USD" style="border-right:1px solid #c0c0c0;text-align:right">{{ parseFloat(store.HTG.total) | formatNumber }}</td>
              <td v-if="filter_rate_id == 1 && store.HTG && store.USD" style="border-right:1px solid #c0c0c0;text-align:right">{{ (parseFloat(store.HTG.total) + parseFloat(store.USD.amount_converted)) | formatNumber }}</td>
              <td v-if="filter_rate_id == 1 && !store.HTG && store.USD" style="border-right:1px solid #c0c0c0;text-align:right">{{ parseFloat(store.USD.amount_converted) | formatNumber }}</td>
              <td v-if="filter_rate_id == 1 && !store.HTG && !store.USD" style="border-right:1px solid #c0c0c0;text-align:right">0.00</td>

              <td v-if="filter_rate_id == 2 && store.HTG && !store.USD" style="border-right:1px solid #c0c0c0;text-align:right">{{ parseFloat(store.HTG.amount_converted) | formatNumber }}</td>
              <td v-if="filter_rate_id == 2 && store.HTG && store.USD" style="border-right:1px solid #c0c0c0;text-align:right">{{ (parseFloat(store.USD.total) + parseFloat(store.HTG.amount_converted)) | formatNumber }}</td>
              <td v-if="filter_rate_id == 2 && !store.HTG && store.USD" style="border-right:1px solid #c0c0c0;text-align:right">{{ parseFloat(store.USD.total) | formatNumber }}</td>
              <td v-if="filter_rate_id == 2 && !store.HTG && !store.USD" style="border-right:1px solid #c0c0c0;text-align:right">0.00</td>
            </tr>
          </tbody>
        </table>
  </div>
</div>
</v-card>

<p style="padding:10px;border-radius:3px;background:#d9edf7; border:1px solid #c7e2f0;text-align:left;color:black;margin-top:30px">
  Le rapport par magasin vous permet de voir (en HTG ou USD) le total des ventes effectués par magasin au cours de la période choisie. Les ventes sont automatiquement converties dans la devise choisie en fonction du taux qui a été sauvegardé lors de la création de la fiche.
</p>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'
import { jsPDF } from "jspdf"
import "jspdf-autotable"

export default {
  name: 'StoresReportView',
  methods: {
    load: function(){
      var self = this;
      this.$axios.get('/reports/stores?from='+localStorage.debut_periode.toLocaleString()+'&to='+localStorage.fin_periode.toLocaleString())
        .then(function (response) {
          console.log(response.data.result)
          self.rows = response.data.result
          self.enable = true          
        }).catch(function(error){
          console.log(error)
        })
    },
    exportPDF: function(){

      const doc = new jsPDF('l', 'pt')

      doc.autoTable({ html: '#content', theme: 'grid' })
      // doc.setFontSize(12);
      // doc.text(10, 10, '#', 'center');
      doc.save("rapport_magasins.pdf");
    },
    exportExcel: function(){
      var table = document.getElementById("content");
      var html = table.outerHTML;
      var url = 'data:application/vnd.ms-excel,' + escape(html); 
      var a = document.createElement('a');
      a.href = url;
      a.download = 'rapport_magasins.xls'; 
      a.click();
    },
    total: function(rate_id){
      var total = 0;
      var self = this
      console.log(this.rows)
      Object.keys(this.rows).forEach(function(key){
        if(rate_id == 1){
          if(self.rows[key].HTG != undefined){
            if(self.rows[key].USD != undefined){
              total = total + parseFloat(self.rows[key].HTG.total) + parseFloat(self.rows[key].USD.amount_converted)
            }else{
              total = total + parseFloat(self.rows[key].HTG.total)
            }
          }else{
            if(self.rows[key].USD != undefined){
              total = total + parseFloat(self.rows[key].USD.amount_converted)
            }else{
              //nothing
            }
          }
        }else{
          if(self.rows[key].HTG != undefined){
            if(self.rows[key].USD != undefined){
              total = total + parseFloat(self.rows[key].USD.total) + parseFloat(self.rows[key].HTG.amount_converted)
            }else{
              total = total + parseFloat(self.rows[key].HTG.amount_converted)
            }
          }else{
            if(self.rows[key].USD != undefined){
              total = total + parseFloat(self.rows[key].USD.total)
            }else{
              //nothing
            }
          }
          
        }
      })
      return total
    },
  },
  created(){
    this.filter_rate_id = localStorage.filter_rate_id
    this.load()    
  },
  data: () => ({
      items: [
        {
          text: 'RAPPORT VENTES PAR MAGASIN',
          disabled: false,
          href: '/',
        }
      ],
      rows: [],
      enable: false,
      loading: false,
      fluid: true,
      filter_rate_id: 1,
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  td{
    font-size: 12px!important;
    vertical-align: middle!important;
  }
  td span, th{
    vertical-align: middle!important;
  }
  th{
    font-size: 12px!important;
  }
  td,th{
    text-align: center;
  }
  .v-select__slot{
    border: 1px solid #ced4da;
    padding: 4px 12px;
    border-radius: 4px;
  }
  .v-text-field > .v-input__control > .v-input__slot:before{
    display: none;
  }
  .v-application .v-btn{
    width: 100%!important;
  }
  .v-text-field{
    margin-top: 0px!important;
    padding-top: 0px!important;
  }
  .v-breadcrumbs__item{
    color: black!important;
  }
  .v-text-field__details{
    display: none!important;
  }
  .v-input__slot{
    margin-bottom: 0px!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
    text-align: center; 
    height:41px!important;
    margin-top:0px;
  }

  .v-btn.reset_modal_button{
    background-color:#1867c0 !important;
    color:white!important;
    height:41px!important;
    margin-top:0px;
  }
  .v-btn.export_modal_button{
    background: #F44336 !important;
    color:white!important;
    height:41px!important;
    margin-top:0px;
  }
  .form-control {
    height: auto!important; 
    padding: 0.45rem 0.75rem!important;
}
</style>